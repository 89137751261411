body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spacer {
  padding: 20px;
}

.zoom-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.zoom-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s ease;
}

.zoom-img img:hover {
  transform: scale(1.2);
}


@media only screen and (min-width: 767px) {
  .banner-image {
    height: 650px;
  }
}

@media only screen and (max-width: 420px) {
  .banner-image {
    height: 550px;
    object-fit: cover;
  }
}


.banner-slide {
  position: relative; /* Ensure each slide is positioned relative to its container */
}

.banner-image {
  width: 100%; /* Ensure the image fills the entire slider */
  filter: brightness(0.7);
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.gallery-image{
  width: 100%; /* Ensure the image fills the entire slider */
  height: 400px;
  object-fit: cover;
}

.overlay,
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9;
  cursor: pointer;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ffc30c;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.slick-dots li button:before
{
    font-size: 14px !important;
}

.slick-dots li.slick-active button:before {
  color: #ffc30c !important;
}